export const useGTag = () => {
  const config = useRuntimeConfig();
  const consentCookie = useCookie<boolean>("cookie-consent");

  if (consentCookie.value === true) {
    //@ts-ignore
    window.dataLayer = window.dataLayer || [];

    function gtag(...rest: unknown[]){
      //@ts-ignore
      dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', config.public.gtag);
  }
}
